* {
    margin: 0;
    padding: 0;
}
html,
body {
    width: 100%;
    height: 100%;
    background: #000C30;
}
#form {
    width: 100%;
    height: 100%;
}