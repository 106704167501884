@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
* { 
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

 .NavLogoGradient h2{
  background-color: #000;
  background-image: linear-gradient(90deg, #00F260, #43A1FF);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
} 


.H1Gradient{
  background-color: #000;
  background-image: linear-gradient(90deg, #00F260, #43A1FF);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}


.loadingicon {
  transform: translateY(275%);
  transition: all 0.2s ease-in-out;
}

.loadingiconCon {
  height: 100vh;
  background-color: #000;
  background-image: linear-gradient(90deg, #1b0758,#0c2dbf);
}



/* Remove mobile blue select background */
input,
textarea,
button,
select,
img,
svg,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.DiscordPulse {
  border-radius: 50px;
    /* padding: 10px 22px; */
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    animation: pulse 1.8s infinite;
}

.DiscordPulse:hover { 
    padding: 10px 22px;
    transition: all 0.2s ease-in-out;
    background: linear-gradient(90deg, #00F260, #436587);
    color: #010606;
    box-shadow: 0 0px 25px rgba(255, 255, 255, .7);
    animation: none;
  @keyframes pulse {
      0% {
          box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
      }
      70% {
          box-shadow: 0 0 0 15px rgba(255,255,255, 0);
      }
      100% {
          box-shadow: 0 0 0 0 rgba(255,255,255, 0);
      }
  }
}

.VerificationCheck { 
  /* background-color: #000; */
  /* background-image: linear-gradient(90deg, #ffb347, #ffcc33); */
  color: #ffcc33;
  font-size: .8em !important;
}

/* 
**ROBOTO Font:**

Thin 100
Thin 100 italic

Light 300
Light 300 italic

Regular 400
Regular 400 italic

Medium 500
Medium 500 italic

Bold 700
Bold 700 italic

Black 900
Black 900 italic

*/

.box {
    /* padding: 0 50px 0 10px;
    border: 2px solid #656464;
    border-radius: 10px; */
  }
  
  .box h2 {
    display: flex;
    align-items: center;
    grid-gap: 2px;
    gap: 2px;
  }
  
  @keyframes cursor-blink {
    0% {
      opacity: 0;
    }
  }
  
  .box h2::after {
    content: "";
    width: 5px;
    height: 20px;
    background: blue;
    display: inline-block;
    animation: cursor-blink 1.5s steps(2) infinite;
  }
.indexedStep {
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .indexedStep.accomplished {
    background-color: rgba(0, 116, 217, 1);
  }
  
.mat-form-field.mat-focused .mat-form-field-label, input.ng-valid+span.mat-form-field-label-wrapper .mat-form-field-label{
    display: none !important;
}
* {
    margin: 0;
    padding: 0;
}
html,
body {
    width: 100%;
    height: 100%;
    background: #000C30;
}
#form {
    width: 100%;
    height: 100%;
}
